<template>

  <!--/inner-page-->
  <div class="inner-banner inner-banner-marcas py-5">
    <section class="w3l-breadcrumb text-left py-sm-5">
      <div class="container">
        <div class="w3breadcrumb-gids">
          <div class="w3breadcrumb-left text-left">
            <h2 class="inner-w3-title"><br /></h2>
          </div>
          <div class="w3breadcrumb-right">
            <ul class="breadcrumbs-custom-path">
              <li><a href="/"></a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--//inner-page-->
  <div style="margin: 8px auto; display: block; text-align: center">
    <!---728x90--->
  </div>

  <!-- -->
  <section class="w3l-witemshny-main py-5">
    <div class="container py-md-4">
      <h3 class="title-w3l">Marcas</h3>
      <!--/row-1-->
      <div class="witemshny-grids row mt-lg-3">

        <template v-for="marca in marcas" :key="marca.id">
          <div class="col-xl-2 col-md-4 col-6 product-incfhny mt-4">
            <div class="weitemshny-grid oposition-relative">
              <a :href="`/produtos?search=${marca.descricao}`" class="d-block zoom"
                ><img
                  :src="marca.imagem?`${publicUrl}${marca.imagem}`:'/assets/images/sem-imagem.jpg'"
                  alt=""
                  class="img-fluid news-image"
              /></a>
              <div class="witemshny-inf"></div>
            </div>
            <h4 class="gdnhy-1 mt-4">
              <a href="/produtos">{{ marca.descricao }}</a>
            </h4>
          </div>
        </template>

      </div>
      <!--//row-1-->
    </div>
  </section>
  <!-- -->
</template>

<script>
// import LayoutHeader from "@/views/layout/layout-site-header";
// import LayoutFooter from "@/views/layout/layout-site-footer";

import { onMounted } from "vue";
import useSite from '@/composables/Site';

export default {
  components: {
    // LayoutHeader,
    // LayoutFooter,
  },

  setup() {
    const { meta, paramsSearch, marcas, getMarcas } = useSite();

    onMounted(function () {
      getMarcas(-1);
    });

    return {
      paramsSearch,
      meta,
      marcas,

      publicUrl: `${process.env.VUE_APP_URL_PUBLIC+'/marca/'}`
    };
  },
};
</script>

<style></style>
